'use client'

import { Chatbot, ChatbotProps } from '@design-system/src/components/Chatbot'
import { ChatbotClientLoggedInProps } from 'app/sites/[site]/_components/ChatbotClientLoggedIn'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { ComponentType, useMemo } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'

import { AiModel, FeaturedChatbotPrompt, Site } from '@models/types'

export type ChatbotClientProps = {
  chatbotImage: Site['chatbotImage']
  chatOpenByDefault: ChatbotProps['openByDefault']
  customGreetingMsg: string
  featuredChatbotPrompts: FeaturedChatbotPrompt[] | undefined
}

export const ChatbotClient: React.FC<ChatbotClientProps> = props => {
  const { data: session, status } = useSession()
  const pathname = usePathname()
  // const status = 'authenticated'

  if (pathname.includes(PAGES_NEXT_PUBLIC_SITES.paymentPortalResult)) return null

  const chatbotImageForChatbot: ChatbotProps['chatbotProfileImage'] = useMemo(() => {
    if (!props.chatbotImage) return undefined
    const { height, width, src } = props.chatbotImage
    if (!height || !width || !src) return undefined
    return { height, width, src }
  }, [props.chatbotImage])

  const ChatbotClientLoggedIn = useMemo<ComponentType<ChatbotClientLoggedInProps> | null>(() => {
    if (status === 'authenticated')
      return dynamic(() =>
        import('app/sites/[site]/_components/ChatbotClientLoggedIn').then(mod => mod.ChatbotClientLoggedIn),
      )
    return null
  }, [status])

  if (status === 'authenticated') {
    if (!ChatbotClientLoggedIn) {
      console.error('ChatbotClientLoggedIn is null but status is authenticated')
      return null
    }

    return (
      <ChatbotClientLoggedIn
        {...props}
        chatbotProfileImage={chatbotImageForChatbot}
        userId={session?.user?.id}
        featuredChatbotPrompts={props.featuredChatbotPrompts}
      />
    )
  }

  if (status === 'loading') {
    return null
  }

  if (status === 'unauthenticated') {
    return (
      <Chatbot
        openByDefault={props.chatOpenByDefault}
        chatbotProfileImage={chatbotImageForChatbot}
        mode="under_paywall"
        handleModelTypeChange={function (modelType: AiModel): void {
          throw new Error('Function not implemented.')
        }}
        handleSendMessage={function (value: string): void {
          throw new Error('Function not implemented.')
        }}
        handlePersonaChange={function (persona: string): void {
          throw new Error('Function not implemented.')
        }}
        messages={[]}
        modelType={'GPT4'}
        personas={[]}
        onResetChatbot={function (): void {
          throw new Error('Function not implemented.')
        }}
        readyState={1}
      />
    )
  }
}
